<template>
    <div class="container">
        <div class="hd_header">
            <div class="g_w1200 g_div">
                <img src="./../../../assets/logo1.png" class="g_logo">
                <span class="g_span"></span>
            </div>
        </div>
        <div class="login_container">
            <div class="g_w1200">
                <div class="login_img">
                    <img src="https://register.ccopyright.com.cn/assets/img/user/loginMain-4f97a3d0.png" alt="">
                </div>
                <div class="login_div">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="ft-main ftw ft-index-block">
                <div class="footer_container">
                    <div class="footer_info">
                        <dl class="footer_info_column">
                            <router-link :to="{name:'indexPage'}">
                                <img src="./../../../assets/logo1.png" alt="" style="height:70px;margin-left:20px">
                            </router-link>
                        </dl>
                        <dl class="footer_info_column">
                            <a href="mailto:284508440@qq.com">
                                <dt>联系我们</dt>
                            </a>
                        </dl>
                        <dl class="footer_info_column">
                            <a href="https://ihago.com/" target="_blank">
                                <dt>关于我们</dt>
                            </a>
                        </dl>
                        <dl class="footer_info_column">
                            <router-link :to="{name:'Useragreement'}" target="_blank">
                                <dt>用户协议</dt>
                            </router-link>
                        </dl>
                        <dl class="footer_info_column">
                            <a href="mailto:284508440@qq.com">
                                <dt>加入我们</dt>
                            </a>
                        </dl>
                        <dl class="footer_info_column">
                            <a href="mailto:284508440@qq.com">
                                <dt>订制服务</dt>
                            </a>
                        </dl>
                    </div>
                </div>
            </div>
            <!-- <a href="mailto:284508440@qq.com">单击这里给284508440@qq.com发电子邮件</a> -->
            <div class="footer_license ftw">
                <!-- <p>
                    <img src="https://static.ibeisai.com/icon/jinghui.ico" alt="" style="width:19px;margin-top:-4px;vertical-align: middle;"> &nbsp;&nbsp;&nbsp;
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002001772" target="blank">
                        <span style="margin-right:15px">陕公网安备61019002001772号</span>
                    </a>
                    <a href="https://beian.miit.gov.cn/" target="blank">陕ICP备2022004291号</a>
                </p> -->
                <p>
                    
                    Copyright © 2024 陕西重构教育科技有限公司，All Rights Reserved.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import '../css/login1.css';
</style>